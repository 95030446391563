import {ADD_DROPDOWN_ONCHANGE, ADD_DROPDOWN_ONCLICK, CLONE_DROPDOWN_ONCHANGE, CLONE_DROPDOWN_ONCLICK, INDUSTRY_CHANGED, LOCATION_CHANGED, MODAL_TOGGLED, PASTE_POPUP_TOGGLE, REPORT_CONTENT_CLEAR, ROW_CONTENT_CHANGED, ROW_CONTENT_COPIED, ROW_CONTENT_CREATED, ROW_CONTENT_INIT, ROW_CONTENT_INIT_FAIL, ROW_CONTENT_INIT_SUCCESS, ROW_CONTENT_PASTED, ROW_CONTENT_RELOADED, ROW_CONTENT_REMOVED, ROW_CONTENT_SELECTED, ROW_SELECT_MODE_CHANGED, UPDATE_LINK_REF, UPDATE_OPTION} from '../../actions/types';

const INITIAL_STATE = {
  maxCount: 10,
  defaultCount: 1,
  addCount: 1,
  cloneCount: 10,
  counter: 0,
  keywordRows: [
    // {'category': '', 'subcategory': '', 'keyword': '', 'relevance': '',
    // 'trend': '', 'rationale': '', 'source': '', 'link': '', 'last_update': ''},
    // {'category': '', 'subcategory': '', 'keyword': '', 'relevance': '',
    //   'trend': '', 'rationale': '', 'source': '', 'link': '', 'last_update': ''},
    // {'category': '', 'subcategory': '', 'keyword': '', 'relevance': '',
    //   'trend': '', 'rationale': '', 'source': '', 'link': '', 'last_update': ''}
  ],
  addDropdownToggle: false,
  headers: [
    {'label': '#', 'value': 'idx'},
    {'label': 'Category', 'value': 'category'},
    {'label': 'Sub-Category', 'value': 'subCategory'},
    {'label': 'Keywords', 'value': 'keyword'},
    // {'label': 'Keyword of Incorporation', 'value': 'keywordOfInCorporation'},
    {'label': 'Relevance', 'value': 'relevance'},
    // {'label': '12M Trend', 'value': 'trend'},
    {'label': 'Rationale', 'value': 'rationale'},
    {'label': 'Sources', 'value': 'source'},
    {'label': 'Link', 'value': 'link'}
    // {'label': 'Last updated', 'value': 'lastUpdate'}
  ],
  rowItemOptions: {},
  loading: true,
  isToggle: false,
  popupType: null,
  isMultipleSelectMode: false,
  modalType: 'search',
  linkRef: []
  // itemToCopied: null,
};

const getCurrentTime = () => {
  const today = new Date();
  return today.getTime();
};

// const NEW_ROW = {'category': '', 'subCategory': '', 'keyword': '', 'keywordOfInCorporation': '', 'relevance': '',
//   'trend': '', 'rationale': '', 'source': '', 'link': '', 'lastUpdate': getCurrentTime(), 'state': 0};

const NEW_ROW = {
  'category': '', 'subCategory': '', 'keyword': '', 'relevance': '',
  'trend': '', 'rationale': '', 'source': '', 'link': '', 'lastUpdate': getCurrentTime(), 'state': 0
};

export default (state = INITIAL_STATE, action) => {
  console.log('[REDUCER] type:', action.type);
  let {keywordRows, rowItemOptions, prevKeywordRows} = state;
  switch (action.type) {
    // case PAGE_INIT:
    //   return {...state,}
    case ROW_CONTENT_RELOADED:
      return state;
    case ROW_CONTENT_INIT:
      return {...state, ...action.payload, loading: true};
    case ROW_CONTENT_INIT_SUCCESS:
      const {rowItemOptions: initOptions, linkRef, ...rest} = action.payload;
      // debugger;
      keywordRows = rest.keywordRows;
      if (keywordRows.length === 0 && keywordRows.constructor === Array)
        keywordRows = Array(state.defaultCount).fill(NEW_ROW);
      return {...state, ...rest, keywordRows, rowItemOptions: initOptions, linkRef, loading: false, isToggle: false};
    case ROW_CONTENT_INIT_FAIL:
      alert('ERROR');
      keywordRows = Array(state.defaultCount).fill(NEW_ROW);
      return {...state, ...action.payload, keywordRows, loading: false, isToggle: false};
    case ADD_DROPDOWN_ONCLICK:
      return {...state, addDropdownToggle: !state.addDropdownToggle};
    case CLONE_DROPDOWN_ONCLICK:
      return {...state, ...action.payload, cloneDropdownToggle: !state.cloneDropdownToggle};
    case CLONE_DROPDOWN_ONCHANGE:
    case ADD_DROPDOWN_ONCHANGE:
      return {...state, ...action.payload};
    case ROW_CONTENT_CHANGED:
      const indexToChange = action.payload.idx;
      const rowToChange = {...state.keywordRows[indexToChange]};
      const {type, value} = action.payload;
      keywordRows = updateKeywordRow(keywordRows, prevKeywordRows, indexToChange, rowToChange, type, value, state.linkRef);
      return {...state, keywordRows};
    case ROW_CONTENT_CREATED:
      const itemToCreated = Array(state.addCount).fill(NEW_ROW);
      return {...state, keywordRows: [...keywordRows, ...itemToCreated]};
    case ROW_CONTENT_COPIED:
      const indexToCopy = state.isSelect;
      const newState = {...state};
      if (!newState.isMultipleSelectMode) {
        const {touched, index, state, lastUpdate, ...itemToCopied} = newState.keywordRows[indexToCopy];
        return {...newState, itemToCopied};
      } else {
        // alert('You cannot copy in [Select Multiple] mode');
        return {state: newState, isToggle: !state.isToggle, modalType: 'warning-copy'};
      }
    case ROW_CONTENT_PASTED:
      const indexToPaste = state.isSelect + 1;
      let contentToPasted = {...state.itemToCopied};
      contentToPasted.lastUpdate = getCurrentTime();
      const itemToPasted = Array(state.cloneCount).fill(contentToPasted);
      keywordRows.splice(indexToPaste, 0, ...itemToPasted);
      return {...state, keywordRows: [...keywordRows], isToggle: !state.isToggle};
    case PASTE_POPUP_TOGGLE:
      if (!state.isMultipleSelectMode) return {...state, isToggle: !state.isToggle, modalType: 'paste'};
      else {
        return {...state, isToggle: !state.isToggle, modalType: 'warning-paste'};
        // return state;
      }
    case ROW_CONTENT_SELECTED:
      const mode = state.isMultipleSelectMode;
      let indexSelected = action.payload.idx;
      if (mode) {
        let isSelectArray = [...state.isSelect];
        const index = isSelectArray.indexOf(indexSelected);
        if (index !== -1) isSelectArray.splice(index, 1);
        else isSelectArray.push(indexSelected);
        indexSelected = isSelectArray;
      }
      return {...state, isSelect: indexSelected};
    case ROW_SELECT_MODE_CHANGED:
      let {isMultipleSelectMode, isSelect} = state;
      isMultipleSelectMode = !isMultipleSelectMode;
      isSelect = (isMultipleSelectMode) ? [] : null;
      return {...state, isMultipleSelectMode, isSelect};
    case ROW_CONTENT_REMOVED:
      if (state.isMultipleSelectMode) {
        const rowAfterRemoved = state.keywordRows.filter((el, i) => !state.isSelect.includes(i));
        return {...state, keywordRows: rowAfterRemoved, isSelect: []};
      } else {
        keywordRows.splice(state.isSelect, 1);
        return {...state, keywordRows: [...keywordRows], isSelect: null};
      }
    case UPDATE_LINK_REF:
      return {...state, linkRef: action.payload.linkRef};
    case LOCATION_CHANGED:
      console.log('REDUCER LOCATION_CHANGED', action.payload.location);
      return {...state, location: action.payload.location};
    case INDUSTRY_CHANGED:
      console.log('REDUCER INDUSTRY_CHANGED', action.payload.industry);
      return {...state, industry: action.payload.industry};
    case UPDATE_OPTION:
      const {idx, type: optionType, value: newOption} = action.payload;
      const newRowItemOptions = updateOptions(optionType, keywordRows, rowItemOptions, newOption, idx);
      return {...state, rowItemOptions: newRowItemOptions};
    case MODAL_TOGGLED:
      return {...state, ...action.payload, isToggle: state.isToggle ? state.isToggle : !state.isToggle};
    case REPORT_CONTENT_CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const updateOptions = (type, keywordRows, rowItemOptions, newOption, idx) => {
  const {category, subCategory, keyword} = keywordRows[idx];
  let categoryOptions = rowItemOptions['category'];
  let itemIndex;
  switch (type) {
    case 'category':
      newOption = {
        ...newOption,
        parent_of: [{label: 'general', value: 'general', category_of: []}]
      };
      categoryOptions = [newOption, ...categoryOptions];
      rowItemOptions['category'] = categoryOptions;
      break;
    case 'subCategory':
      newOption = {
        ...newOption,
        category_of: []
      };
      let item = categoryOptions.find((option, i) => {
        if (option.value === category) {
          itemIndex = i;
          return true;
        } else return false;
      });
      let subCategoryOptions = item['parent_of'];
      subCategoryOptions = [newOption, ...subCategoryOptions];
      categoryOptions[itemIndex] = {...item, parent_of: subCategoryOptions};
      rowItemOptions['category'] = categoryOptions;
      break;
    case 'keyword':
      item = categoryOptions.find((option, i) => {
        if (option.value === category) {
          itemIndex = i;
          return true;
        } else return false;
      });
      subCategoryOptions = item['parent_of'];
      let itemChildIndex;
      let itemChild = subCategoryOptions.find((option, i) => {
        if (option.value === subCategory) {
          itemChildIndex = i;
          return true;
        } else return false;
      });
      let keywordOptions = itemChild['category_of'];
      console.log({itemChild});
      keywordOptions = [newOption, ...keywordOptions];
      subCategoryOptions[itemChildIndex] = {...itemChild, category_of: keywordOptions};
      categoryOptions[itemIndex] = {...item, parent_of: subCategoryOptions};
      rowItemOptions['category'] = categoryOptions;
  }
  return rowItemOptions;
};

const updateSubcategoryOptions = (keywordRows, rowItemOptions, newOption, idx) => {
  const {category} = keywordRows[idx];
  let itemIndex;
  let item = rowItemOptions['category'].find((option, i) => {
    if (option.value === category) {
      itemIndex = i;
      return true;
    } else return false;
  });
  if (item) {
    let {parent_of: parentOf} = item;
    parentOf = parentOf ? parentOf : [];
    parentOf.push(newOption);
    rowItemOptions['subCategory'] = parentOf;
    item = {...item, parent_of: parentOf};
    rowItemOptions['category'][itemIndex] = item;
  }
  return rowItemOptions;
};

const updateKeywordOptions = (keywordRows, rowItemOptions, newOption, idx) => {
  const {category, subCategory} = keywordRows[idx];
  let categoryIndex, subCategoryIndex, categoryItem, subCategoryItem;
  categoryItem = rowItemOptions['category'].find((option, i) => {
    if (option.value === category) {
      categoryIndex = i;
      return true;
    } else return false;
  });

  if (categoryItem && !subCategoryItem) {
    let {category_of: categoryOf} = categoryItem;
    categoryOf = categoryOf ? categoryOf : [];
    categoryOf.push(newOption);
    rowItemOptions['category'][categoryIndex] = {...rowItemOptions['category'][categoryIndex], category_of: categoryOf};
  }

  if (subCategory && categoryItem) {
    const {parent_of: parentOf} = categoryItem;
    subCategoryItem = parentOf.find((option, i) => {
      if (option.value === subCategory) {
        subCategoryIndex = i;
        return true;
      } else return false;
    });
    if (subCategoryItem) {
      let {category_of: subCategoryCategoryOf} = parentOf[subCategoryIndex];
      subCategoryCategoryOf = subCategoryCategoryOf ? subCategoryCategoryOf : [];
      subCategoryCategoryOf.push(newOption);
      parentOf[subCategoryIndex] = {...parentOf[subCategoryIndex], category_of: subCategoryCategoryOf};
      rowItemOptions['category'][categoryIndex] = {...rowItemOptions['category'][categoryIndex], parent_of: parentOf};
    }
  }

  return rowItemOptions;
};

const updateKeywordRow = (keywordRows, prevKeywordRows, indexToChange, rowToChange, type, value, linkRef) => {
  const prevValue = rowToChange[type];
  if (prevValue !== value) {
    rowToChange[type] = value;
    const {touched} = rowToChange;
    if (type === 'source') {
      const ref = linkRef.find(ref => {
        if (ref) return ref.source === value;
        else return false;
      });
      rowToChange['link'] = ref ? ref.link : '';
    }
    keywordRows = [...keywordRows];
    if (touched !== undefined) rowToChange = {...rowToChange, touched: true};
    keywordRows[indexToChange] = rowToChange;
  }
  return keywordRows;
};
