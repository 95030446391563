import React, {Component} from 'react';
import {Nav} from 'reactstrap';
import PropTypes from 'prop-types';
import {AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import DefaultHeaderDropdown from './DefaultHeaderDropdown';

import logo from '../../assets/img/brand/logo.png';
import sygnet from '../../assets/img/brand/logo2.png';

const propTypes = {
  children: PropTypes.node
};


class DefaultHeader extends Component {
  render() {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile/>
        <AppNavbarBrand
          href={'#/'}
          full={{src: logo, width: 100, height: 40, alt: 'InvBots Logo'}}
          minimized={{src: sygnet, width: 30, height: 30, alt: 'InvBots Logo'}}
        />
        <AppSidebarToggler className="d-md-down-none h-100 b-r-1 px-3" display="lg"/>
        <Nav className="ml-auto" navbar>
          <DefaultHeaderDropdown accnt feedUser={this.props.userData}/>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;

export default DefaultHeader;
