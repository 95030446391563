import React, {Component} from 'react';
import CheckAuthorization from '../../views/CheckAuthorization/CheckAuthorization';

class DefaultNavItem extends Component {
  constructor(props) {
    super(props);
    this.item = this.props.path;
    this.authenticateHandler = this.authenticateHandler.bind(this);
    this.authorizeHandler = this.authorizeHandler.bind(this);
  }

  authenticateHandler = isAuthAccess => {
    this.props.authorizeHandler(this.item, isAuthAccess);
  };

  authorizeHandler = (authItem, isAuthAccess) => {
    if (!isAuthAccess)
      this.item.children = this.item.children.filter(item => item.url !== authItem.url);
    if (this.item.children !== undefined && this.item.children.length === 0)
      this.props.authorizeHandler(this.item, false);
  };

  render() {
    if (this.item.children) {
      return (
        <div>
          {this.item.children.map((child, index) => {
            return (
              <DefaultNavItem
                path={child}
                key={index}
                authorizeHandler={this.authorizeHandler}
                userDetails={this.props.userDetails}
                authJson={this.props.authJson}
              />);
          })}
          <CheckAuthorization
            path={this.item.url}
            authenticateHandler={this.authenticateHandler}
            feedUserDetails={this.props.userDetails}
            feedAuthJson={this.props.authJson}
          />
        </div>
      );
    } else return (
      <CheckAuthorization
        path={this.item.url}
        authenticateHandler={this.authenticateHandler}
        feedUserDetails={this.props.userDetails}
        feedAuthJson={this.props.authJson}
      />
    );
  }
}

export default DefaultNavItem;
