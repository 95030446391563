import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {signOut} from '../../utilities/firebase';
import {Redirect} from 'react-router-dom';

const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));
const COMPANY_MAPPING_REPORTS = '/company-mapping/report';

const DefaultHeaderDropdown = props => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [redirectCompanyMappingReport, setRedirectCompanyMappingReport] = useState(false);

  useEffect(() => {
    return () => {
      setRedirectCompanyMappingReport(false);
    };
  });

  const onClickHandler = async e => {
    e.preventDefault();
    await signOut();
    window.location.reload();
  };

  const DropDownItems = [];

  const dropAccnt = () => {
    return (
      <Dropdown nav className="d-md-down-none" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownToggle nav>
          {redirectCompanyMappingReport &&
          <Redirect to={{pathname: COMPANY_MAPPING_REPORTS, state: {personal: true}}}/>}
          <img src={props.feedUser.profilePhoto || `assets/img/avatars/${getRandomInt(8)}.jpg`} className="img-avatar"
               title={props.feedUser.displayName ? props.feedUser.displayName : 'Guest'} alt=''/>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem header tag="div"
                        className="text-center"><strong>Welcome, {props.feedUser.displayName ? props.feedUser.displayName : 'Guest'} </strong></DropdownItem>
          <DropdownItem header tag="div"
                        className="text-center"><img
            src={props.feedUser.profilePhoto || `assets/img/avatars/${getRandomInt(8)}.jpg`} className="img-avatar"
            title={props.feedUser.displayName ? props.feedUser.displayName : 'Guest'} alt=''/></DropdownItem>
          {DropDownItems.map(({label, key, icon, onClick}) => (
            <DropdownItem className="text-center" onClick={onClick} key={key}>
              <i className={icon}/>{label}
            </DropdownItem>
          ))}
          <DropdownItem className="text-center" onClick={onClickHandler.bind(this)}><i className="fa fa-sign-out"/> Sign
            Out</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const {accnt} = props;
  return accnt ? dropAccnt() : null;
};

export default DefaultHeaderDropdown;
