import {AUTH_API, INTRANET_API_SERVER_URL, IS_LOCAL} from './constantsFile';
import * as firebase from 'firebase';
import 'firebase/auth';
import * as config from '../config';
import {fetchGet, fetchPost} from '../service/fetchHelperFn';
import {mergeUser} from './userDataMerge';

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

export const getAuthStatus = async () => {
  if (IS_LOCAL)
    return {
      uuid: 'd714fb1d-0a02-4d64-9cce-2f85675df516',
      firebaseId: '2f85675df516d714fb1d',
      intranetUid: 3,
      firstName: null,
      lastName: null,
      displayName: 'a',
      email: null,
      emailVerified: true,
      mobileVerified: false,
      gender: 'Male',
      education: 'University',
      location: 'Hong Kong',
      occupation: 'Others',
      isActive: true,
      profilePhoto: 'https://invbots-users.sgp1.digitaloceanspaces.com/d/d714fb1d-0a02-4d64-9cce-2f85675df516/d714fb1d-0a02-4d64-9cce-2f85675df516=1565950575164=Capture.PNG',
      createdAt: 1574928104516,
      lastLoginAt: 1562732028820,
      role: [2]
    };
  else {
    try {
      const csstToken = document.cookie.replace(/(?:(?:^|.*;\s*)csst\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      const authStatus = await fetchPost(`${AUTH_API}/check-auth-status`, {}, {
        headers: {
          Authorization: `Bearer ${csstToken}`
        },
        credentials: 'include'
      });

      if (!authStatus.error) {
        const {customToken} = authStatus.result;
        const {user} = await firebase.auth().signInWithCustomToken(customToken);
        const {result: invbotsUser} = await fetchGet(INTRANET_API_SERVER_URL + '/users', {firebase_id: user.uid});

        // merge invbots user data && firebase user data
        const mergedUser = mergeUser(invbotsUser, user);
        if (!!mergedUser.intranetUid || mergedUser.role.includes(3))
          return mergedUser; // authorized => return merged user
        else return {}; // unauthorized => return empty user
      } else return null; // redirect => return null
    } catch (err) {
      console.error(err);
    }
  }
};

export const signOut = async () => {
  await fetchPost(`${AUTH_API}/sign-out`, {}, {
    credentials: 'include'
  });
  await firebase.auth().signOut();
};
