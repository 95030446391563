import React, {Component} from 'react';
import {Col, Container, InputGroup, Row} from 'reactstrap';
import {AUTH_URL, LOCAL_URL} from '../../../utilities/constantsFile';

class Page403 extends Component {
  render() {
    console.log(this.props);
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">403</h1>
                <h4 className="pt-3">Forbidden</h4>
                <p className="text-muted float-left">
                  You are not authorized to access the content you required.
                </p>
              </div>
              <InputGroup className='input-prepend'>
                <h1>Have you logged in?</h1>
                <h3>
                  If not, click{' '}
                  <a href={`${AUTH_URL}?redirect=${encodeURIComponent(this.props.redirectURL || LOCAL_URL)}`}>here</a>
                  {' '}to login
                </h3>
                {/*<br />*/}
                {/*<h3>Otherwise, Bye</h3>*/}
                {/*<Input*/}
                {/*size="16"*/}
                {/*type="text"*/}
                {/*placeholder="What are you looking for?"*/}
                {/*/>*/}
                {/*<InputGroupAddon addonType="append">*/}
                {/*<Button color="info">Search</Button>*/}
                {/*</InputGroupAddon>*/}
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page403;
