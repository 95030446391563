/**
 *  @author: Kenny Chan <kenny.chan@invbot.com>
 *  @since: 2018-12-04
 */
import React from 'react';
import Loadable from 'react-loadable';


const Loading = () => {
  return <div>Loading...</div>;
};

const Home = Loadable({loader: () => import ('./views/Home'), loading: Loading});
const Display = Loadable({loader: () => import ('./views/Display/Tableau'), loading: Loading});
const DataProcessing = Loadable({loader: () => import ('./views/DataProcessing/DataProcessing'), loading: Loading});
const Dashboard = Loadable({loader: () => import('./views/Dashboard'), loading: Loading});
const DashboardTicker = Loadable({loader: () => import('./views/DashboardTicker'), loading: Loading});
const ReportEdit = Loadable({loader: () => import('./views/ReportEditModule/ReportEdit'), loading: Loading});
const ReportEditInstructions = Loadable({
  loader: () => import('./views/ReportEditModule/Instructions'),
  loading: Loading
});
// const ReportRevertTable = Loadable({loader: () => import('./views/ReportEditModule/RevertTable'), loading: Loading});
const ReportViews = Loadable({loader: () => import('./views/ReportEditModule/ReportViews'), loading: Loading});
const ReportInputSheet = Loadable({
  loader: () => import('./views/ReportEditModule/InputSheet/view'),
  loading: Loading
});
const UndownloadedReports = Loadable({
  loader: () => import('./views/ReportEditModule/UndownloadedReports/view'),
  loading: Loading
});
const ReportStatusView = Loadable({
  loader: () => import('./views/ReportEditModule/StatusView/view'),
  loading: Loading
});
const Export = Loadable({
  loader: () => import('./views/ReportEditModule/Export'),
  loading: Loading
});
const AllocationByTable = Loadable({
  loader: () => import ('./views/ReportEditModule/AllocationTable/ByTable'),
  loading: Loading
});
const AllocationByReport = Loadable({
  loader: () => import ('./views/ReportEditModule/AllocationTable/ByReport'),
  loading: Loading
});

const TestCaseValidation = Loadable({
  loader: () => import ('./views/ReportEditModule/TestCaseValidation/index'),
  loading: Loading
});

const ResultAnnouncement = Loadable({loader: () => import('./views/Sec/ResultAnnouncement'), loading: Loading});
const XbrlKeyGrouping = Loadable({loader: () => import('./views/XbrlKeyGrouping/XbrlKeyGrouping'), loading: Loading});
const XbrlModule = Loadable({loader: () => import('./views/InvbotsKeyEquationModule'), loading: Loading});
const ScheduleJobTable = Loadable({
  loader: () => import('./views/ScheduleJobTable/ScheduleJobTable'),
  loading: Loading
});
const ParentKeyManagement = Loadable({loader: () => import('./views/InvbotsKeyParentKeyManagement'), loading: Loading});
const InvbotsKeySummary = Loadable({loader: () => import('./views/InvbotsKeySummary'), loading: Loading});
const ExternalWebServices = Loadable({loader: () => import('./views/BATools/ExternalWebServices'), loading: Loading});
const BetaTestTableContainer = Loadable({
  loader: () => import('./views/Tools/BetaTestTableContainer'),
  loading: Loading
});
const BetaTestHome = Loadable({loader: () => import('./views/Tools/BetaTest'), loading: Loading});
const StanAdmin = Loadable({loader: () => import('./views/Tools/StanAdmin'), loading: Loading});

// Modelbots Service
const ModelbotsService = Loadable({loader: () => import('./views/ModelbotsService/Instruction'), loading: Loading});
const Assignment = Loadable({loader: () => import('./views/ModelbotsService/Assignment'), loading: Loading});
const TableCutting = Loadable({
  loader: () => import('./views/ModelbotsService/TableCutting/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="source" {...props}/>;
  },
  loading: Loading
});
const TableEditing = Loadable({
  loader: () => import('./views/ModelbotsService/TableEditing/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="editing" {...props}/>;
  },
  loading: Loading
});
const TableReview = Loadable({
  loader: () => import('./views/ModelbotsService/TableReview/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="validation" {...props}/>;
  },
  loading: Loading
});
const ModelbotsServiceValidation = Loadable({
  loader: () => import('./views/ModelbotsService/Validation/Validations'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props}/>;
  },
  loading: Loading
});
const FixedTableProfile = Loadable({
  loader: () => import('./views/ModelbotsService/FixedTableProfile/FixedTableProfile'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props}/>;
  },
  loading: Loading
});
const StateManagement = Loadable({
  loader: () => import('./views/ModelbotsService/StateManagement/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="all" {...props}/>;
  },
  loading: Loading
});

// Table Reader Service
const TableReaderCutting = Loadable({
  loader: () => import('./views/TableReaderService/TableCutting/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="source" {...props}/>;
  },
  loading: Loading
});
const TableReaderEditing = Loadable({
  loader: () => import('./views/TableReaderService/TableEditing/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="editing" {...props}/>;
  },
  loading: Loading
});
const TableReaderStateManagement = Loadable({
  loader: () => import('./views/TableReaderService/StateManagement/Viewer'),
  render(loaded, props) {
    const Component = loaded.default;
    return <Component task="all" {...props}/>;
  },
  loading: Loading
});

//EquationBuilder
const EquationSummaryPage = Loadable({loader: () => import('./views/EquationBuilder/EquationSummary'), loading: Loading});
const EquationAssignmentPage = Loadable({loader: () => import('./views/EquationBuilder/EquationAssignment'), loading: Loading});
const EquationTranslationPage = Loadable({loader: () => import('./views/EquationBuilder/EquationTranslation'), loading: Loading});
// const EquationBuilder = Loadable({
//   loader: () => import('./views/EquationBuilder/EquationBuilderPage'),
//   loading: Loading
// });

// -- start of company mapping
const CompanyMappingModule = Loadable({
  loader: () => import('./views/CompanyMappingModule/ReportViewer/AllReports.js'),
  loading: Loading
});
const CompanyMappingReportViewer = Loadable({
  loader: () => import('./views/CompanyMappingModule/ReportViewer/Report.js'),
  loading: Loading
});
const CompanyMappingReportEditor = Loadable({
  loader: () => import('./views/CompanyMappingModule/CompanyMappingModule.js'),
  loading: Loading
});
const CompanyMappingKeywordEditor = Loadable({
  loader: () => import ('./views/CompanyMappingModule/KeywordEditor'),
  loading: Loading
});
const CompanyMappingStatistics = Loadable({
  loader: () => import('./views/CompanyMappingModule/Statistics'),
  loading: Loading
});
const CompanyMappingCleaning = Loadable({
  loader: () => import('./views/CompanyMappingModule/Cleaning'),
  loading: Loading
});
const CompanyMappingApproval = Loadable({
  loader: () => import ('./views/CompanyMappingModule/Approval'),
  loading: Loading
});
const CompanyMappingMonitoring = Loadable({
  loader: () => import ('./views/CompanyMappingModule/Monitor'),
  loading: Loading
});
// -- end of company mapping

//invbots key
const InvbotsKeyStatistics = Loadable({
  loader: () => import ('./views/InvbotsKeyEquationModule/Statistics'),
  loading: Loading
});

//invbots key item name matching
const InvbotsKeyItemMatching = Loadable({
  loader: () => import ('./views/InvbotsKeyItemMatching/view'),
  loading: Loading
});


// -- reits
const ReitsModule = Loadable({
  loader: () => import('./views/ReitsModule/Viewer/Viewer.js'),
  loading: Loading
});

// -- Newsletter of Reits
const NewsletterModule = Loadable({
  loader: () => import('./views/ReitsModule/NewsletterModule/News.js'),
  loading: Loading
});

const ReportViewer = Loadable({loader: () => import('./views/ReportViewer/ReportViewerHome'), loading: Loading});
// const ReportViewerTable = Loadable({loader: () => import('./views/ReportViewer/ReportViewerHome'),loading: Loading});
// const TableViewer = Loadable({loader: () => import('./views/ReportViewer/TableViewerHome'),loading: Loading});
const DateReportViewer = Loadable({loader: () => import('./views/ReportViewer/DateReportViewer'), loading: Loading});

// const DOI = Loadable({loader: () => import('./views/Tools/BetaTest/ProgramConstructor'), loading: Loading});
//
// const ExportModelbots = Loadable({loader: () => import('./views/Tools/ExportModelbots'), loading: Loading});
const MergeStatus = Loadable({loader: () => import('./views/MergeStatus/MergeStatus'), loading: Loading});
const InvbotsKeyStatus = Loadable({
  loader: () => import('./views/InvbotsKeyStatus/InvbotsKeyStatus'),
  loading: Loading
});

const TestcaseStatus = Loadable({
  loader: () => import('./views/TestCaseStatus/TestCaseStatus'),
  loading: Loading
});

const EquationStatus = Loadable({
  loader: () => import('./views/EquationStatus/EquationStatus'),
  loading: Loading
});


const ValidationData = Loadable({
  loader: () => import('./views/ValidationData/ValidationData'),
  loading: Loading
});

const StatusDashboard = Loadable({
  loader: () => import('./views/StatusDashboard/DashboardLayout'),
  loading: Loading
});

const EditTableInfo = Loadable({
  loader: () => import('./views/ReportEditModule/EditTableInfo/EditTableInfo'),
  loading: Loading
});

const ConvertFile = Loadable({
  loader: () => import('./views/ReportEditModule/ConvertFile/ConvertFile'),
  loading: Loading
});


const TestcaseStatusByHSI = Loadable.Map({
  loader: {
    Table: () => import('./views/TestCaseStatus/TestCaseStatusByIndex')
  },
  loading: Loading,
  render(loaded, props) {
    let Table = loaded.Table.default;
    return <Table index={'HSI'}/>;
  }
});
const TestcaseStatusByHSTI = Loadable.Map({
  loader: {
    Table: () => import('./views/TestCaseStatus/TestCaseStatusByIndex')
  },
  loading: Loading,
  render(loaded, props) {
    let Table = loaded.Table.default;
    return <Table index={'HSTI'}/>;
  }
});

const Attendance = Loadable({loader: () => import('./views/Attendance'), loading: Loading});

const routes = [
  //-- Default Layout
  {path: '/', name: 'Home', component: Home, exact: true},
  {path: '/dashboard', name: 'Dashboard', component: Dashboard, exact: true},
  {path: '/ticker-dashboard', name: 'Ticker Dashboard', component: DashboardTicker, exact: true},
  {path: '/display', name: 'Display', component: Display, exact: true},
  {path: '/display/tableau', name: 'Display', component: Display},
  {path: '/display/export', name: 'A-Share Model', component: Export},
  {path: '/display/dataprocessing', name: 'Data Processing', component: DataProcessing},
  // Modelbots Service
  {path: '/modelbots-service/assignment', name: 'Assignment', component: Assignment},
  {path: '/modelbots-service', name: 'Modelbots Service', component: ModelbotsService, exact: true},
  {path: '/modelbots-service/table-cutting', name: 'Table Cutting', component: TableCutting},
  {path: '/modelbots-service/table-editing', name: 'Table Editing', component: TableEditing},
  {path: '/modelbots-service/table-review', name: 'Table Review', component: TableReview},
  {path: '/modelbots-service/validation', name: 'Validation', component: ModelbotsServiceValidation},
  {path: '/modelbots-service/fixed-table-profile', name: 'Profile', component: FixedTableProfile},
  {path: '/modelbots-service/state-management', name: 'State Management', component: StateManagement},
  // Table Reader Service
  {path: '/table-reader-service', name: 'Table Reader Service', component: TableReaderStateManagement, exact: true},
  {path: '/table-reader-service/table-cutting', name: 'Table Reader Cutting', component: TableReaderCutting},
  {path: '/table-reader-service/table-editing', name: 'Table Reader Editing', component: TableReaderEditing},
  {path: '/table-reader-service/state-management', name: 'Table Reader State Management', component: TableReaderStateManagement},
  //-- Report Edit Module
  {path: '/reports_edit_module', name: 'Reports Edit Module', component: ReportEditInstructions, exact: true},
  {path: '/reports_edit_module/instructions', name: 'Instructions', component: ReportEditInstructions},
  {path: '/reports_edit_module/reports_edit', name: 'Reports Edit Page', component: ReportEdit},
  // {path: '/reports_edit_module/revert_table', name: 'Reports Revert Table Page', component: ReportRevertTable},
  {path: '/reports_edit_module/report_views', name: 'Reports Views Page', component: ReportViews},
  {path: '/reports_edit_module/input_sheet', name: 'Reports Input Sheet', component: ReportInputSheet},
  {path: '/reports_edit_module/statusView', name: 'Reports Status', component: ReportStatusView},
  {path: '/reports_edit_module/UndownloadedReports', name: 'Undownloaded Reports', component: UndownloadedReports},
  {path: '/reports_edit_module/allocation_table', name: 'Allocation By Table', component: AllocationByTable},
  {path: '/reports_edit_module/allocation_report', name: 'Allocation By Report', component: AllocationByReport},
  {path: '/reports_edit_module/edit_table_info', name: 'Edit Table Info', component: EditTableInfo},
  {path: '/reports_edit_module/convert_file', name: 'Covert PDF/image', component: ConvertFile},
  {path: '/reports_edit_module/test_case_validation', name: 'Test Case Validation', component: TestCaseValidation},
  //-- SEC
  {path: '/sec', name: 'SEC', component: ResultAnnouncement, exact: true},
  {path: '/sec/result_announcement', name: 'Result Announcement', component: ResultAnnouncement},
  //-- Schedule Job
  {path: '/schedule_job', name: 'Schedule Job Table', component: ScheduleJobTable},
  //-- Company Mapping
  {path: '/company-mapping', name: 'Company Mapping', component: CompanyMappingStatistics, exact: true},
  {path: '/company-mapping/statistics', name: 'Statistics', component: CompanyMappingStatistics},
  {path: '/company-mapping/reports', name: 'All Reports', component: CompanyMappingModule},
  {path: '/company-mapping/view/:report_id', name: 'Report Viewer', component: CompanyMappingReportViewer},
  {path: '/company-mapping/edit/:report_id', name: 'Report Edit', component: CompanyMappingReportEditor},
  {path: '/company-mapping/create/:report_id', name: 'Report Create', component: CompanyMappingReportEditor},
  {path: '/company-mapping/keywords', name: 'Keywords', component: CompanyMappingKeywordEditor},
  {path: '/company-mapping/create', name: 'Report Create', component: CompanyMappingReportEditor},
  {path: '/company-mapping/cleaning', name: 'Cleaning', component: CompanyMappingCleaning},
  {path: '/company-mapping/approval', name: 'Approval', component: CompanyMappingApproval},
  {path: '/company-mapping/monitoring', name: 'Monitoring', component: CompanyMappingMonitoring},
  //-- xbrl_key_grouping
  {path: '/xbrl_key_grouping/xbrl_key_grouping', name: 'Xbrl Key Grouping', component: XbrlKeyGrouping, exact: true},
  //-- invbots key
  {path: '/invbots-key/edit', name: 'Key & Equation Builder Module', component: XbrlModule, exact: true},
  {path: '/invbots-key/statistics', name: 'Statistics', component: InvbotsKeyStatistics},
  {path: '/invbots-key/parent-key-management', name: 'Parent Key Management', component: ParentKeyManagement},
  {path: '/invbots-key/invbots-key-summary', name: 'Invbots Key Summary', component: InvbotsKeySummary},
  //--equation builder
  {path: '/equation-builder/equation-summary', name: 'Equation Builder - Summary', component: EquationSummaryPage, exact: true},
  {path: '/equation-builder/equation-assignment', name: 'Equation Builder - Assignment', component: EquationAssignmentPage},
  {path: '/equation-builder/equation-translation', name: 'Equation Builder - Translation', component: EquationTranslationPage},
  {path: '/invbots-key/invbots-key-development', name: 'Invbots Key Development', component: InvbotsKeyItemMatching},
  //-- reits
  {path: '/invbots-reits', name: 'REITs', component: ReitsModule, exact: true},
  {path: '/invbots-reits/viewer', name: 'REITs', component: ReitsModule},
  {path: '/invbots-reits/newsletter', name: 'REITs Newsletter', component: NewsletterModule}, //-- reits newsletter
  //-- BATools
  {path: '/batools', name: 'BATools', component: ExternalWebServices, exact: true},
  {path: '/batools/external_web_services', name: 'External Web Services', component: ExternalWebServices},
  {path: '/batools/report-viewer', name: '8K Report Viewer Selection', component: ReportViewer},
  // {path: '/batools/report-viewer-table',name: '8K Report Viewer Selection',component: ReportViewerTable},
  // {path: '/batools/table-viewer',name: '8K Report Table Viewer',component: TableViewer},
  {path: '/batools/date-report-viewer', name: '8K Date Report Viewer', component: DateReportViewer},
  //-- Tools
  {path: '/beta-test', name: 'Tools', component: BetaTestHome, exact: true},
  {path: '/beta-test/home', name: 'Home', component: BetaTestHome},
  //-- Beta Test
  {path: '/beta-test/beta-test-table', name: 'Beta Tests', component: BetaTestTableContainer},
  //-- Admin
  {path: '/admin', name: 'Admin', component: StanAdmin, exact: true},
  {path: '/admin/stan-admin', name: 'Stock Analyser Admin', component: StanAdmin},
  {path: '/status/overview', name: 'Status Overview', component: StatusDashboard},
  {path: '/status/testcasehsi', name: 'Status Overview Testcase HSI', component: TestcaseStatusByHSI},
  {path: '/status/testcasehsti', name: 'Status Overview Testcase HSTI', component: TestcaseStatusByHSTI},
  {path: '/status/mergestatus', name: 'Merge Status', component: MergeStatus},
  {path: '/status/invbotskeystatus', name: 'Invobts Key Status', component: InvbotsKeyStatus},
  {path: '/status/testcasestatus', name: 'Test Cases Status', component: TestcaseStatus},
  {path: '/status/equationstatus', name: 'Equation Status', component: EquationStatus},
  {path: '/status/datavalidation', name: 'Data Validation', component: ValidationData},
  {path: '/attendance', name: 'Attendance', component: Attendance}
  //-- Equation builder
  // { path: '/equation_builder', name: 'Equation Builder', component: EquationBuilder }
];

// {path: '/tools/doi-analysis', name: 'DOI', component: DOI},
// {path: '/tools/export_modelbots', name: 'Export Modelbots', component: ExportModelbots},

// config.forEach(route => {
//   const {path, name} = route;
//   route['component'] = Loadable({loader: () => import('./views/Tools/BetaTest/ProgramConstructor'), loading: Loading});
//   // route['path'] = '/tools/doi-analysis';
//   // route['name'] = 'DOI';
//   routes.push(route);
// });


export default routes;
