import React, {useEffect, useState} from 'react';
import UserContext from './UserContext';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.scss';
// Containers
import {DefaultLayout} from './containers';
// Pages
import {Page403, Page404, Page500} from './views/Pages';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as config from './config';
import {AUTH_URL} from './utilities/constantsFile';
import {isEmpty} from './utilities/frontendCommonFunctions';
import {getAuthStatus} from './utilities/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

const Redirect = () => {
  const redirectURL = `${AUTH_URL}?redirect=${encodeURIComponent(window.location.href)}`;
  window.location.replace(redirectURL);
  return null;
};

const PrivateRoute = ({component: Component, user, ...rest}) => (
  <Route {...rest}
         render={props => rest.isAuthenticated ? (
           <UserContext.Provider value={{user}}>
             <Component {...props} />
           </UserContext.Provider>
         ) : <Redirect/>}
  />
);

const App = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  useEffect(() => {
    (async () => {
      const userData = await getAuthStatus();
      setUser(userData);
      setIsAuthenticated(userData && !isEmpty(userData));
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <p/>;
  } else {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/403" name="Page 403" component={Page403}/>
          <Route exact path="/404" name="Page 404" component={Page404}/>
          <Route exact path="/500" name="Page 500" component={Page500}/>
          <PrivateRoute
            user={user}
            isAuthenticated={isAuthenticated}
            path="/"
            name="Home"
            component={DefaultLayout}
            {...props}
          />
        </Switch>
      </HashRouter>
    );
  }
};

export default App;
