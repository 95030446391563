import React from 'react';
import {MONTHS} from './constantsFile';

export function numberWithCommas(number) {
  let [int, rest] = number.toString().split('.');
  int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (rest) return `${int}.${rest}`;
  else return int;
}

const paddy = (num, padLen, padChar) => {
  const pad_char = typeof padChar !== 'undefined' ? padChar : '0';
  const pad = new Array(1 + padLen).join(pad_char);
  return (pad + num).slice(-pad.length);
};

export const TIME_CONVERTER = (unixTimestamp, isString) => {
  const jsTime = new Date(unixTimestamp);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = jsTime.getFullYear();
  const month = months[jsTime.getMonth()];
  const monthInt = jsTime.getMonth() + 1;
  const date = jsTime.getDate();
  const hour = jsTime.getHours();
  const min = jsTime.getMinutes();
  const sec = jsTime.getSeconds();
  if (!isString) return {date, month, year, hour, min, sec, monthInt};
  else return `${paddy(year, 4)}/${paddy(monthInt, 2)}/${paddy(date, 2)} ${paddy(hour, 2)}:${paddy(min, 2)}:${paddy(sec, 2)}`;
};

export const unix2Time = (unixTime, isString = true) => {
  if (!unixTime) return '-';
  const jsTime = new Date(parseInt(unixTime));
  const year = jsTime.getFullYear();
  const month = MONTHS[jsTime.getMonth()];
  const monthInt = jsTime.getMonth() + 1;
  const date = jsTime.getDate();
  const hour = jsTime.getHours();
  const min = jsTime.getMinutes();
  const sec = jsTime.getSeconds();
  if (!isString) return {date, month, year, hour, min, sec, monthInt};
  else return `${paddy(year, 4)}/${paddy(monthInt, 2)}/${paddy(date, 2)} ${paddy(hour, 2)}:${paddy(min, 2)}:${paddy(sec, 2)}`;
};

export const renderDatetime = (datetime, notBreakLine) => {
  const defaultBreakLine = notBreakLine || false;
  const datetimeArr = TIME_CONVERTER(datetime, true).split(' ');
  if (!defaultBreakLine) return <div>{datetimeArr.map(x => <p key={x}>{x}</p>)}</div>;
  else return <div><p>{datetimeArr.reduce((x, y) => `${x} ${y}`)}</p></div>;
};

export function isEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const genId = (prefix = '', length = 9) => prefix + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
