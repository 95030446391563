import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import Loadable from 'react-loadable';
import {AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import AUTH_CONFIG from '../../resource/config/auth_config';
import NAV_TOOL_CONFIG from '../../resource/config/nav_tools_config';
import ROUTE_CONFIG from '../../resource/config/routes_config';

import DefaultNavItem from './DefaultNavItem';
import CheckAuthorization from '../../views/CheckAuthorization/CheckAuthorization';
import UserContext from '../../UserContext';

class DefaultLayout extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.authItems = navigation;
    this.state = {
      auth: {},
      nav: navigation,
      routes: routes,
      isLoading: true,
      扲到config: false,
      isAuthorizing: true,
      isAuthAccess: false,
      lastItem: navigation.items[navigation.items.length - 1]
    };
  }

  componentWillMount = () => {
    const authConfig = this.getAuthConfig();
    const navConfig = this.getNavConfig();
    const routesConfig = this.getRoutesConfig();
    this.setState({auth: authConfig, nav: navConfig, routes: routesConfig, 扲到config: true});
  };

  getNavConfig = () => {
    // return this.state.nav
    try {
      const toolsConfig = NAV_TOOL_CONFIG;
      let items = [];
      let counter = 0;
      let clone = [...this.state.nav.items];
      for (let item of clone) {
        const {name} = item;
        if (name === 'Beta Test') {
          item.children = toolsConfig;
        }
        items.push({...item});
        counter++;
      }
      return {items: items};
    } catch (e) {
      return this.state.nav;
    }
  };

  getRoutesConfig = () => {
    let {routes} = this.state;
    try {
      const routesConfig = ROUTE_CONFIG;
      for (let route of routesConfig) {
        //console.log('rouites name', route.name);
        route['component'] = Loadable({
          loader: () => import(`../../views/Tools/BetaTest/ProgramConstructor`),
          loading: () => <p>is Loading...</p>
        });
        routes.push(route);
      }
      return routes;
    } catch (e) {
      return this.state.route;
    }
  };

  getAuthConfig = () => {
    try {
      return AUTH_CONFIG;
    } catch (e) {
      return this.state.auth;
    }
  };

  authenticateHandler = isAuthAccess => {
    this.setState({isAuthorizing: false, isAuthAccess: isAuthAccess});
  };

  authorizeHandler = (authItem, isAuthAccess) => {
    if (isAuthAccess)
      this.authItems.items = this.authItems.items.map(item => authItem.url === item.url ? authItem : item);
    else this.authItems.items = this.authItems.items.filter(item => item.url !== authItem.url);
    if (this.state.lastItem.url === authItem.url)
      this.updateIsLoading(false);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({isAuthorizing: true});
  }

  updateIsLoading = state => {
    this.setState({
      isLoading: state
    });
  };

  render() {
    const userData = this.context.user;
    const {nav} = this.state;
    if (this.state.isLoading) {
      if (!this.state.扲到config)
        return (<p/>);
      else {
        return (
          <div>
            {nav.items.map((item, i) => {
              return (
                <DefaultNavItem
                  key={i}
                  path={item}
                  authorizeHandler={this.authorizeHandler}
                  userDetails={userData}
                  authJson={this.state.auth}
                  {...this.props}
                />);
            })}
          </div>
        );
      }
    } else {
      return (
        <div className='app'>
          <AppHeader fixed>
            <DefaultHeader userData={userData}/>
          </AppHeader>
          <div className='app-body'>
            <AppSidebar fixed display='lg'>
              <AppSidebarHeader/>
              <AppSidebarForm/>
              <AppSidebarNav navConfig={this.authItems} {...this.props} />
              <AppSidebarFooter/>
              <AppSidebarMinimizer/>
            </AppSidebar>
            <main className='main' style={{backgroundColor: '#cbffd9'}}>
              <AppBreadcrumb appRoutes={routes}/>
              <Container fluid>
                <Switch>
                  {routes.map((route, idx) => {
                    let temp = route.path.split('/');
                    const length = temp.length;
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        componentPath={[]}
                        render={props => {
                          if (this.state.isAuthorizing) {
                            return (
                              <CheckAuthorization
                                path={route.path}
                                authenticateHandler={this.authenticateHandler}
                                feedUserDetails={userData}
                                feedAuthJson={this.state.auth}
                                loopAllLayers
                              />
                            );
                          } else {
                            if (this.state.isAuthAccess) {
                              return (
                                <route.component {...props} toolId={temp[length - 1]} componentPath={[]}/>
                              );
                            } else return <Redirect to="/403"/>;
                          }
                        }}
                      />
                    ) : null;
                  })}
                  <Redirect from='/' to='/reports_edit_module/reports_edit'/>
                </Switch>
              </Container>
            </main>
            {/*<AppAside fixed>*/}
            {/*<DefaultAside />*/}
            {/*</AppAside>*/}
          </div>
          <AppFooter>
            <DefaultFooter/>
          </AppFooter>
        </div>
      );
    }
  }
}

export default DefaultLayout;
