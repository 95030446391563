export default {
  items: [
    {
      name: 'Ticker Dashboard',
      url: '/ticker-dashboard'
    },
    {
      name: 'Modelbots CN',
      url: '/display',
      icon: 'icon-graph',
      children: [
        {
          name: '---->　Tableau',
          url: '/display/tableau'
        },
        {
          name: '---->　A-Share Model',
          url: '/display/export'
        },
        {
          name: '---->　Data Processing',
          url: '/display/dataprocessing'
        }
      ]
    },
    {
      name: 'Modelbots Service',
      url: '/modelbots-service',
      icon: 'icon-graph',
      badge: {
        variant: 'danger',
        text: 'NEW'
      },
      children: [
        {
          name: '---->　Instruction',
          url: '/modelbots-service'
        },
        {
          name: '---->　Table Cutting',
          url: '/modelbots-service/table-cutting'
        },
        {
          name: '---->　Table Editing',
          url: '/modelbots-service/table-editing'
        },
        {
          name: '---->　Table Review',
          url: '/modelbots-service/table-review'
        },
        {
          name: '---->　State Management',
          url: '/modelbots-service/state-management'
        },
        {
          name: '---->　Validation',
          url: '/modelbots-service/validation'
        },
        {
          name: '---->　Profile',
          url: '/modelbots-service/fixed-table-profile'
        },
        {
          name: '---->　Assignment',
          url: '/modelbots-service/assignment'
        }
      ]
    },

    {
      name: 'Table Reader Service',
      url: '/table-reader-service',
      icon: 'icon-graph',
      badge: {
        variant: 'danger',
        text: 'NEW'
      },
      children: [
        {
          name: '---->　Table Cutting',
          url: '/table-reader-service/table-cutting'
        },
        {
          name: '---->　Table Editing',
          url: '/table-reader-service/table-editing'
        },
        {
          name: '---->　State Management',
          url: '/table-reader-service/state-management'
        }
      ]
    },
    {
      name: 'Reports Editing Module',
      url: '/reports_edit_module',
      icon: 'icon-note',
      children: [
        {
          name: '---->　Instructions',
          url: '/reports_edit_module/instructions'
        },
        {
          name: '---->　View',
          url: '/reports_edit_module/report_views',
          badge: {
            variant: 'danger',
            text: 'IMPORTANT'
          }
        },
        {
          name: '---->　Edit',
          url: '/reports_edit_module/reports_edit',
          badge: {
            variant: 'danger',
            text: 'IMPORTANT'
          }
        },
        {
          name: '---->　Input Sheet',
          url: '/reports_edit_module/input_sheet'
        },
        {
          name: '---->　Undownlaoded Reports',
          url: '/reports_edit_module/UndownloadedReports'
        },
        {
          name: '---->　Report Status',
          url: '/reports_edit_module/statusView'
        },
        {
          name: '---->　Allocation By Table',
          url: '/reports_edit_module/allocation_table'
        },
        {
          name: '---->　Allocation By Report',
          url: '/reports_edit_module/allocation_report'
        },
        {
          name: '---->　Edit Table Info',
          url: '/reports_edit_module/edit_table_info'
        },
        {
          name: '---->　Convert File',
          url: '/reports_edit_module/convert_file'
        },
        {
          name: '---->　Test Case Validation',
          url: '/reports_edit_module/test_case_validation'
        }
      ]
    },
    {
      name: 'Company Mapping',
      url: '/company-mapping',
      icon: 'icon-graph',
      children: [
        {
          name: '---->　Create',
          url: '/company-mapping/create'
        },
        {
          name: '---->　Report View / Edit',
          url: '/company-mapping/reports'
        },
        {
          name: '---->　Keyword View / Edit',
          url: '/company-mapping/keywords'
        },
        {
          name: '---->　Statistics',
          url: '/company-mapping/statistics'
        },
        {
          name: '---->　Cleaning',
          url: '/company-mapping/cleaning'
        },
        {
          name: '---->　Approval',
          url: '/company-mapping/approval'
        },
        {
          name: '---->　Monitoring',
          url: '/company-mapping/monitoring'
        }
      ]
    },
    {
      name: 'Invbots Key',
      url: '/invbots-key',
      icon: 'icon-key',
      children: [
        {
          name: '---->　Edit',
          url: '/invbots-key/edit'
        },
        {
          name: '---->　Statistics',
          url: '/invbots-key/statistics'
        },
        {
          name: '---->　Invbots Key Development',
          url: '/invbots-key/invbots-key-development'
        },
        {
          name: '----> Parent Key Management',
          url: '/invbots-key/parent-key-management'
        },
        {
          name: '----> Invbots Key Summary',
          url: '/invbots-key/invbots-key-summary'
        }
      ]
    },
    {
      name: 'Equation Builder',
      url: '/equation-builder',
      icon: 'icon-key',
      children: [
        {
          name: '---->　Equation Summary',
          url: '/equation-builder/equation-summary'
        },
        {
          name: '---->　Equation Assignment',
          url: '/equation-builder/equation-assignment'
        },
        {
          name: '---->　Equation Translation',
          url: '/equation-builder/equation-translation'
        }
      ]
    },
    // {name: 'Schedule Job Report', url: '/schedule_job', icon: 'icon-list'},
    // {
    //   name: 'Xbrl Key Grouping',
    //   url: '/xbrl_key_grouping',
    //   icon: 'icon-note',
    //   children: [
    //     {
    //       name: 'Xbrl Key Grouping',
    //       url: '/xbrl_key_grouping/xbrl_key_grouping'
    //     }
    //   ]
    // },
    // {
    //   name: 'Beta Test',
    //   url: '/beta-test',
    //   icon: 'icon-wrench',
    //   children: []
    // },
    // {
    //   name: 'BA Tools',
    //   url: '/batools',
    //   icon: 'icon-wrench',
    //   children: [
    //     {
    //       name: 'External Web Services',
    //       url: '/batools/external_web_services'
    //     },
    //     {
    //       name: '8K Report Viewer',
    //       url: '/batools/report-viewer'
    //     }
    //   ]
    // },
    {
      name: 'REITs',
      url: '/invbots-reits',
      icon: 'icon-graph',
      badge: {
        variant: 'danger',
        text: 'NEW'
      },
      children: [
        {
          name: '---->　REIT List',
          url: '/invbots-reits/viewer'
        },
        {
          name: '---->　Newsletter',
          url: '/invbots-reits/newsletter'
        }
      ]
    },
    {
      name: 'Status',
      url: '/status',
      children: [
        {
          name: '---->　Overview',
          url: '/status/overview'
        },
        {
          name: '---->　Merge Status',
          url: '/status/mergestatus'
        },
        {
          name: '---->　Invbots Key Status',
          url: '/status/invbotskeystatus'
        },
        {
          name: '---->　Test Case Status',
          url: '/status/testcasestatus'
        },
        {
          name: '---->　Equation Status',
          url: '/status/equationstatus'
        },
        {
          name: '---->　Data Validation',
          url: '/status/datavalidation'
        }
      ]
    },
    {
      name: 'Attendance',
      url: '/attendance'
    }
  ]
};
