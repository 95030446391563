require('dotenv').config();
const environment = process.env.REACT_APP_ENVIRONMENT;
//- General Setting
export let isDataProduction, isWebProduction, isLocalhost;
isDataProduction = environment === 'production';
isWebProduction = environment !== 'localhost';
isLocalhost = environment === 'development' || environment === 'localhost';

// if (process.env.REACT_APP_ENVIRONMENT === 'production') {
//   isDataProduction = true;
//   isWebProduction = true;
//   isLocalhost = false;
// } else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
//   isDataProduction = false;
//   isWebProduction = true;
//   isLocalhost = false;
// } else if (process.env.REACT_APP_ENVIRONMENT === 'localhost') {
//   isDataProduction = false;
//   isWebProduction = false;
//   isLocalhost = true;
// }
console.log('environment:', environment);
console.log(process.env, environment, isDataProduction, isWebProduction, isLocalhost);
// const {isDataProduction, isWebProduction, isLocalhost} = isProduction;

export const COMPANY_SITE_URL = isDataProduction ? 'https://www.invbots.com' : 'https://uat.invbots.dev';
export const LOCAL_URL = isDataProduction ? 'https://internal.invbots.com/intranet/#' : 'https://internal.invbots.dev/intranet/#';
// export const DATA_SERVER_URL = isDataProduction ? 'https://api.invbots.com' : 'https://api.invbots.dev';
export const DATA_SERVER_URL = isDataProduction ? 'https://api.invbots.com' : (isLocalhost ? 'https://api.invbots.dev' : 'https://api.invbots.dev');
export const INVBOTS_KEY_URL = isDataProduction ? 'https://api.invbots.com' : (isLocalhost ? 'https://api.invbots.dev' : 'https://api.invbots.dev');
export const MODELBOTS_SERVICE = `https://modelbots-service.invbots.${isDataProduction ? 'com' : 'dev'}`;
export const TABLE_READER = `${DATA_SERVER_URL}/table-reader-application`;
export const DATA_SERVER_URL3 = 'http://159.89.194.50/intranet-api-test/report-edit';
export const INTRANET_URL = isDataProduction ? 'https://internal.invbots.com/#' : isLocalhost ? 'http://localhost:3000/#' : 'https://internal.invbots.dev/#';


export const FLASK_SERVER_URL = 'http://167.99.74.217';

export const INTRANET_API_SERVER_URL = DATA_SERVER_URL + '/intranet-api'; // IMPORTANT+
export const GRAPHQL_URL = INTRANET_API_SERVER_URL + '/graphql';
// export const GRAPHQL_URL = 'http://localhost:3001/intranet-api/graphql';

export const API_SERVER_URL = DATA_SERVER_URL + '/api';

export const AUTH_URL = isDataProduction ? 'https://accounts.invbots.com' : 'https://accounts.invbots.dev';
export const AUTH_API = `${DATA_SERVER_URL}/auth`;


// export const LOCAL_URL = 'http://localhost:8000';
//- Report Edit Setting
export const REPORT_EDIT_URL = INTRANET_API_SERVER_URL + '/report-edit';
console.log(REPORT_EDIT_URL);
export const PDF_API_URL = isLocalhost ? 'http://localhost:5000/api' : 'http://157.230.39.181:5000/api';  // real

console.log('pdf parsing server url: ' + process.env.REACT_APP_PDF_API_URL);

//- Equation Builder
export const EQUATION_BUILDER_URL = INTRANET_API_SERVER_URL + '/equation-builder';

//- Config Files
export const IS_PRODUCTION = isWebProduction;
export const IS_LOCAL = isLocalhost;
export const NAV_TOOL_CONFIG = 'nav_tools_config.json';
export const ROUTE_CONFIG = 'routes_config.json';
export const AUTH_CONFIG = 'auth_config.json';

// export const REPORT_EDIT_URL = 'http://159.89.194.50/intranet-api-test/report-edit';  //test
export const REPORT_LOCATION = [
  {name: 'Hong Kong', abbr: 'hk'},
  {name: 'The United States', abbr: 'us'},
  {name: 'China', abbr: 'cn'}];

export const REPORT_EDIT_EXCHANGE = ['sehk', 'nyse', 'nasdaq', 'szse', 'sse'];
export const REPORT_EDIT_ROLE = ['checker', 'supervisor', 'leader'];
export const REPORT_EDIT_REPORT_LANG = ['en', 'zh-hk', 'zh-cn'];
export const REPORT_EDIT_PERIOD = ['q1', 'q2', 'q3', 'q4', 'fy'];
export const REPORT_EDIT_TABLE_NAME = ['is', 'bs', 'cfs', 'cis', 'cfs_note'];
export const REPORT_EDIT_LOCATION = ['cn', 'us'];
export const REPORT_EDIT_REPORT_TYPE = ['an', 'ar', 'ir', 'pr', 'qr', '6-k', '8-k', '10-k', '10-q', '20-f'];
export const ALPHABET_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const STATUS_LIST = {'0': 'Pending', '1': 'Requested', '2': 'Approved', '3': 'Paid', '-1': 'Rejected'};
export const EMPTY_STRING = 'N/A';
export const INVBOTS_KEY_VALIDATION_RULES = { // default required: true
  hk: [
    {
      description: 'child_invbots_key exists, other fields remains empty',
      rule: [{name: 'child_invbots_key'}]
    },
    {
      description: 'child_invbots_key exists, other fields must all exist',
      rule: [
        {name: 'parent_alias_ley', required: false},
        {name: 'child_alias_key', required: false},
        {name: 'parent_invbots_key', required: false},
        {name: 'parent_label', required: false},
        {name: 'child_invbots_key', required: true},
        {name: 'child_label', required: false}
      ]
    }
  ],
  us: [
    {
      description: 'child_invbots_key exists, other fields remains empty',
      rule: [{name: 'child_invbots_key'}]
    },
    {
      description: 'child_invbots_key exists, other fields must all exist',
      rule: [
        {name: 'parent_alias_ley', required: false},
        {name: 'child_alias_key', required: false},
        {name: 'parent_invbots_key', required: false},
        {name: 'parent_label', required: false},
        {name: 'child_invbots_key', required: true},
        {name: 'child_label', required: false}
      ]
    }
  ]
};

export const REITS = `${INTRANET_API_SERVER_URL}/reits`;
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const COMPANY_MAPPING = `${INTRANET_API_SERVER_URL}/company-mapping`;
export const COMPANY_MAPPING_API = `${INTRANET_API_SERVER_URL}/company-mapping`;
export const COMPANY_MAPPING_INDUSTRIES_LIST = [
  {name: 'Automobiles & Components', value: 'Automobiles & Components'},
  {name: 'Banks', value: 'Banks'},
  {name: 'Capital Goods', value: 'Capital Goods'},
  {name: 'Commercial & Professional Services', value: 'Commercial & Professional Services'},
  {name: 'Communication Services', value: 'Communication Services'},
  {name: 'Consumer Durables & Apparel', value: 'Consumer Durables & Apparel'},
  {name: 'Consumer Services', value: 'Consumer Services'},
  {name: 'Diversified Financials', value: 'Diversified Financials'},
  {name: 'Energy', value: 'Energy'},
  {name: 'Food & Staples Retailing', value: 'Food & Staples Retailing'},
  {name: 'Food, Beverage & Tobacco', value: 'Food, Beverage & Tobacco'},
  {name: 'Health Care Equipment & Services', value: 'Health Care Equipment & Services'},
  {name: 'Household & Personal Products', value: 'Household & Personal Products'},
  {name: 'Insurance', value: 'Insurance'},
  {name: 'Materials', value: 'Materials'},
  {name: 'Media & Entertainment', value: 'Media & Entertainment'},
  {name: 'Pharmaceuticals, Biotechnology & Life Sciences', value: 'Pharmaceuticals, Biotechnology & Life Sciences'},
  {name: 'Real Estate', value: 'Real Estate'},
  {name: 'Retailing', value: 'Retailing'},
  {name: 'Semiconductors & Semiconductor Equipment', value: 'Semiconductors & Semiconductor Equipment'},
  {name: 'Software & Services', value: 'Software & Services'},
  {name: 'Technology Hardware & Equipment', value: 'Technology Hardware & Equipment'},
  {name: 'Transportation', value: 'Transportation'}
];
export const COMPANY_MAPPING_VALIDATION_TYPES = ['subCategory', 'rationale'];
export const COMPANY_MAPPING_VALIDATION_RULES = {
  country: {
    subCategory: [],
    rationale: []
  },
  region: { // city
    subCategory: [],
    rationale: []
  },
  currency: {
    subCategory: [],
    rationale: []
  },
  company: {
    subCategory: ['general', 'sehk', 'nasdaq', 'nyse', 'szse', 'sse', 'otcmkts', 'unlisted_company'],
    rationale: ['competitor', 'customer', 'm&a', 'partner', 'subsidiary', 'supplier']
  },
  commodity: {
    subCategory: [],
    rationale: []
  },
  'product/service': {
    subCategory: [],
    rationale: []
  },
  industry: {
    subCategory: [],
    rationale: []
  },
  theme: {
    subCategory: [],
    rationale: []
  }
};

export const COMPANY_MAPPING_RELEVANCE = [
  {label: 'N/A', value: -1},
  {label: 'High', value: 2},
  {label: 'Neutral', value: 1},
  {label: 'Low', value: 0}
];

export const COMPANY_MAPPING_RATIONALE = [
  {label: 'competitor', value: 'competitor'},
  {label: 'customer', value: 'customer'},
  {label: 'm&a', value: 'm&a'},
  {label: 'partner', value: 'partner'},
  {label: 'subsidiary', value: 'subsidiary'},
  {label: 'supplier', value: 'supplier'},
  {label: 'expense', value: 'expense'},
  {label: 'exposure', value: 'exposure'},
  {label: 'raw material', value: 'raw material'},
  {label: 'headquarter', value: 'headquarter'},
  {label: 'revenue', value: 'revenue'}
];

//invbots key
export const INVBOTS_KEY = `${INTRANET_API_SERVER_URL}/invbots-key`;

export const INVBOTS_KEY_LOCATION = [
  {name: 'all', value: 'all'},
  {name: 'hk', value: 'hk'},
  {name: 'us', value: 'us'},
  {name: 'cn', value: 'cn'}
];

export const INVBOTS_KEY_LOCATION1 = [
  {name: 'hk', value: 'hk'},
  {name: 'us', value: 'us'},
  {name: 'cn', value: 'cn'}
];


export const INVBOTS_KEY_TYPE = [
  {name: 'standard', value: 'standard'},
  {name: 'alias', value: 'alias'}
];

export const INVBOTS_KEY_RELATIONSHIP = [
  {name: 'parent', value: 'parent'},
  {name: 'child', value: 'child'}
];

