export const mergeUser = (invbotsData, firebaseData, type = 'email') => {
  let user = {};
  switch (type) {
    case 'email':
      user = {
        uuid: invbotsData.uuid,
        firebaseId: firebaseData.uid,
        intranetUid: invbotsData.intranet_uid,
        firstName: invbotsData.first_name,
        lastName: invbotsData.last_name,
        displayName: invbotsData.display_name,
        email: invbotsData.email,
        emailVerified: firebaseData.emailVerified,
        mobile: invbotsData.mobile,
        mobileVerified: invbotsData.mobile_is_verified,
        gender: invbotsData.gender,
        education: invbotsData.education,
        location: invbotsData.location,
        occupation: invbotsData.occupation,
        isActive: invbotsData.is_active,
        profilePhoto: invbotsData.profile_photo,
        createdAt: firebaseData.metadata.a,
        lastLoginAt: firebaseData.metadata.b,
        role: invbotsData.role
      };
      break;
    case 'google':
      break;
  }
  return user;
};
