//--KeywordEditor
export const CATEGORY_CHANGED = 'category_changed';
export const SUB_CATEGORY_CHANGED = 'subcategory_changed';
export const ROW_CONTENT_RELOADED = 'row_content_reloaded';
export const ROW_CONTENT_SELECTED = 'row_content_selected';
export const ROW_CONTENT_CHANGED = 'row_content_changed';
export const ROW_CONTENT_CREATED = 'row_content_created';
export const ROW_CONTENT_REMOVED = 'row_content_removed';
export const ROW_CONTENT_COPIED = 'row_content_copied';
export const ROW_CONTENT_PASTED = 'row_content_pasted';
export const ROW_CONTENT_INIT = 'row_content_init';
export const ROW_CONTENT_INIT_SUCCESS = 'row_content_init_success';
export const ROW_CONTENT_INIT_FAIL = 'row_content_init_fail';
export const PASTE_POPUP_TOGGLE = 'paste_popup_toggle';
export const ADD_DROPDOWN_ONCLICK = 'add_dropdown_onclick';
export const CLONE_DROPDOWN_ONCLICK = 'clone_dropdown_onclick';
export const CLONE_DROPDOWN_ONCHANGE = 'clone_dropdown_changed';
export const ROW_SELECT_MODE_CHANGED = 'row_select_mode_changed';
export const ADD_DROPDOWN_ONCHANGE = 'add_dropdown_changed';
export const ROWS_CONTENT_REMOVED = 'rows_content_removed';
export const MODAL_TOGGLED = 'modal_toggled';
export const LOCATION_CHANGED = 'location_changed';
export const INDUSTRY_CHANGED = 'industry_changed';
export const ROW_REMOVED = 'row_remove';
export const ROW_ADDED = 'row_add';
export const ROW_COPIED = 'row_copy';
export const UPDATE_LINK_REF = 'link_ref_updated';
export const UPDATE_OPTION = 'option_updated';
export const UPDATE_CATEGORY_OPTIONS = 'category_options_updated';
export const UPDATE_SUBCATEGORY_OPTIONS = 'subcategory_options_updated';
export const UPDATE_KEYWORD_OPTIONS = 'keyword_options_updated';
export const REPORT_CONTENT_CLEAR = 'contnet_clear';
